/* src/styles/Header.css */

.header {
  position: relative;
  width: 100%;
  height: 100vh; /* Full screen height */
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.top-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 65px; /* Adjust the height if necessary */
  margin-right: 1px; /* Add some space between the logos */
}

.navigation {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem; /* Adjust the gap if necessary */
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #00aced;
}

.centered-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px; /* Adjust the gap between logos if necessary */
  margin-bottom: 30vh; /* Adjust the bottom margin to position logos vertically centered */
}

.centered-logo {
  height: 300px; /* Adjust the size of the logos if necessary */
}
