/* src/styles/AboutUs.css */
.about-us-section {
    padding: 4rem 2rem;
    text-align: center;
    background-color: #000; /* Change to your desired background color */
    color: white;
    border-radius: 8px;
  }
  
  .about-us-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-us-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #00d1ff; /* Change to your desired heading color */
  }
  
  .about-us-content p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
  
  .about-us-image img {
    width: 100%;
    border-radius: 8px;
  }
  