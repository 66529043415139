/* src/styles/VisionMission.css */
.vision-mission-section {
    padding: 4rem 2rem;
    text-align: left;
    background-color: #000; /* Change to your desired background color */
    color: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .vision-mission-content {
    max-width: 600px;
  }
  
  .vision-mission-content .text-section {
    margin-bottom: 2rem;
  }
  
  .vision-mission-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #00d1ff; /* Change to your desired heading color */
  }
  
  .vision-mission-content p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  .vision-mission-image img {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
  }
  