/* src/styles/Chargers.css */
.chargers-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #000; /* Change to your desired background color */
  background-image: url('../assets/images/Charger-bg.png'); /* Background image */
  background-size: cover;
  background-position: center;
  color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
}

.chargers-content {
  max-width: 500px;
  flex: 1;
}

.chargers-title {
  font-weight: bold;
  font-size: 2rem; /* Adjust the font size as needed */
}

.chargers-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.charger-icon {
  text-align: center;
  margin: 0 1rem;
}

.icon-circle {
  width: 80px; /* Adjust the size as needed */
  height: 80px; /* Adjust the size as needed */
  background-color: #1e90ff; /* Highlight color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.charger-icon img {
  width: 40px; /* Adjust the size as needed */
  height: 40px; /* Adjust the size as needed */
}

.chargers-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.chargers-images-row {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.chargers-images-center {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.charger-image {
  width: 100%; /* Adjust the size as needed */
  max-width: 300px; /* Adjust the size as needed */
  height: auto;
  border-radius: 8px;
}
