/* src/styles/Section.css */
.section {
  padding: 4rem 2rem;
  text-align: center;
}

.section img {
  width: 100%;
  max-width: 600px;
  margin-bottom: 2rem;
}

.chargers-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #000; /* Change to your desired background color */
  color: white;
  padding: 2rem;
  border-radius: 8px;
}

.chargers-content {
  max-width: 500px;
}

.chargers-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.charger-icon {
  text-align: center;
}

.charger-icon img {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  margin-bottom: 0.5rem;
}
