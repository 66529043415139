/* src/styles/App.css */

.App {
    text-align: center;
    height: 100vh;
    background: url('../assets/images/background.png') no-repeat center center/cover;
    color: white;
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  